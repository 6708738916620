import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "session-iii",
      "style": {
        "position": "relative"
      }
    }}>{`Session III`}<a parentName="h1" {...{
        "href": "#session-iii",
        "aria-label": "session iii permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`We now know both the map of our distributed stars and their various constellations. We can read and use this map to navigate the shared universe we are all building together. However, DAOs don't exist in just one place. Often, their functions are distributed across different networks, depending on the specific needs they have and the contexts within which they operate. `}</p>
    <h2 {...{
      "id": "across-the-universe",
      "style": {
        "position": "relative"
      }
    }}>{`Across the Universe`}<a parentName="h2" {...{
        "href": "#across-the-universe",
        "aria-label": "across the universe permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <h3 {...{
      "id": "objective",
      "style": {
        "position": "relative"
      }
    }}>{`Objective`}<a parentName="h3" {...{
        "href": "#objective",
        "aria-label": "objective permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Get experience setting up a cross platform Zodiac mod.`}</p>
    <h3 {...{
      "id": "preparation",
      "style": {
        "position": "relative"
      }
    }}>{`Preparation`}<a parentName="h3" {...{
        "href": "#preparation",
        "aria-label": "preparation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://blog.gnosis.pm/introducing-safesnap-the-first-in-a-decentralized-governance-tool-suite-for-the-gnosis-safe-ea67eb95c34f"
        }}>{`Introducing SafeSnap: The first in a decentralized governance tool suite for the Gnosis Safe`}</a>{`, Gnosis (2021); `}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://gnosis.github.io/zodiac/docs/tutorial-module-reality/get-started"
        }}>{`Reality Module tutorial`}</a>{`, Gnosis (2021);`}</li>
      <li parentName="ol">{`Optional: `}<a parentName="li" {...{
          "href": "https://docs.ethhub.io/built-on-ethereum/oracles/what-are-oracles/"
        }}>{`What are Oracles?`}</a>{`, EthHub (2021)`}</li>
    </ol>
    <h3 {...{
      "id": "application",
      "style": {
        "position": "relative"
      }
    }}>{`Application`}<a parentName="h3" {...{
        "href": "#application",
        "aria-label": "application permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <ul>
      <li parentName="ul">{`Zodiac Reality (5 minutes plus 5 minute Q&A)`}
        <ul parentName="li">
          <li parentName="ul">{`Review how Zodiac Reality works`}</li>
          <li parentName="ul">{`Brief introduction to the concept of oralces`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Zodiac Reality (50 minutes plus 5 minute Q&A)`}
        <ul parentName="li">
          <li parentName="ul">{`Guided tutorial on setting up Zodiac Reality Module on a `}<a parentName="li" {...{
              "href": "https://rinkeby.gnosis-safe.io/app/"
            }}>{`Rinkeby Gnosis Safe`}</a></li>
          <li parentName="ul">{`Follow along as a Zodiac Reality Module is connected to a Snapshot space`}</li>
          <li parentName="ul">{`Participants vote on 1 proposal on a test Gnosis Guild Snapshot space`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "recording",
      "style": {
        "position": "relative"
      }
    }}>{`Recording`}<a parentName="h3" {...{
        "href": "#recording",
        "aria-label": "recording permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <Video src="https://www.youtube-nocookie.com/embed/lGn8itUxfQY" mdxType="Video" />
    <p><img parentName="p" {...{
        "src": "/images/build/zodiac_meanings.png",
        "alt": "Zodiac Meanings"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      